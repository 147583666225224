import React, { useState } from 'react';
import './FABUploadMedia.css';
import UploadMediaModal from './UploadMediaModal'; // Import the UploadMediaModal component
import VoiceNoteModal from './VoiceNoteModal';

function FABUploadMedia() {
  const [showModal, setShowModal] = useState(false); // State to track modal visibility
  const [uploadPhoto, setUploadPhoto] = useState(false); // Track if Upload Photo is selected
  const [showVoiceNoteModal, setShowVoiceNoteModal] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Function to handle closing the UploadMedia form
  const closeUploadMedia = () => {
    setUploadPhoto(false);
  };

  // Function to handle file upload (Photo Library)
  const handleFileUpload = () => {
    setUploadPhoto(true); // Show the UploadMedia form
    setShowModal(false); // Close the options modal
  };

  const handleVoiceNote = () => {
    setShowVoiceNoteModal(true); // Show the UploadVoiceNote form
    setShowModal(false); // Close the options modal
  };

  return (
    <div>
      {/* FAB Button to open the modal */}
      <button className="fab-main" onClick={openModal}>
        <span className="material-icons">add</span>
      </button>

      {/* Media Options Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Close Modal Button */}
            <button className="modal-close" onClick={closeModal}>
              <span className="material-icons">close</span>
            </button>

            {/* Media Options */}
            <div className="media-options">
              {/* Photo Upload Option */}
              <button className="media-option" onClick={handleFileUpload}>
                <span className="material-icons">photo_camera</span>
                <p>Upload Media</p>
              </button>

              {/* Voice Note Option */}
              <button className="media-option" onClick={handleVoiceNote}>
                <span className="material-icons">mic</span>
                <p>Voice Note</p>
              </button>
            </div>
          </div>
        </div>
      )}

      {showVoiceNoteModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <VoiceNoteModal onClose={() => setShowVoiceNoteModal(false)} />
            </div>
          </div>
      )}

      {uploadPhoto && (
        <div className="modal-overlay">
          <div className="modal-content">
            <UploadMediaModal addMedia={() => {}} currentPrompt="Some prompt" onClose={closeUploadMedia} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FABUploadMedia;
