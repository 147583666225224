import React, { useState, useRef } from 'react';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  addDoc,
  storage,
  firestore,
  serverTimestamp,
} from './firebase-config';
import './VoiceNoteModal.css'; // Custom styles for the voice note modal

function VoiceNoteModal({ onClose, eventCode, userId, displayName }) {
  const [audioUrl, setAudioUrl] = useState(null); // URL of the recorded audio
  const [isRecording, setIsRecording] = useState(false); // Recording status
  const [caption, setCaption] = useState(''); // Caption text
  const [loading, setLoading] = useState(false); // Loading state for submission
  const mediaRecorderRef = useRef(null); // Ref to store the MediaRecorder instance
  const audioBlobRef = useRef(null); // Ref to store the audio blob

  const handleVoiceNote = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      let audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const url = URL.createObjectURL(audioBlob);
        audioBlobRef.current = audioBlob; // Save the audio blob for uploading
        setAudioUrl(url); // Save the audio URL for playback
        setIsRecording(false);
        console.log('Voice note recorded:', url);
      };

      mediaRecorderRef.current = mediaRecorder; // Store the MediaRecorder instance
      mediaRecorder.start();
      setIsRecording(true);
    } catch (err) {
      console.error('Error recording voice note:', err);
      alert('Could not access the microphone.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop the recording
    }
  };

  const handlePlayBack = () => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.play();
    }
  };

  const handleRedo = () => {
    setAudioUrl(null); // Reset the audio URL for re-recording
    audioBlobRef.current = null;
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleSubmit = async () => {
    if (!audioBlobRef.current || !userId || !caption.trim()) {
      console.error('Missing audio, user ID, or caption.');
      return;
    }

    setLoading(true);

    try {
      const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
      const storagePath = `events/${eventCode}/media/${uniqueId}`;
      const storageRef = ref(storage, storagePath);

      // Upload the audio blob to Firebase Storage
      await uploadBytes(storageRef, audioBlobRef.current);
      const fileUrl = await getDownloadURL(storageRef);

      // Prepare media document data
      const newMedia = {
        prompt: 'Voice Note',
        displayname: displayName,
        caption: caption,
        src: fileUrl,
        name: `voice-note-${uniqueId}.webm`,
        timestamp: serverTimestamp(),
        userId: userId,
        type: 'voice-note',
        uniqueId: uniqueId,
      };

      // Save the media document to Firestore
      const docPath = `events/${eventCode}/media`;
      const docRef = await addDoc(collection(firestore, docPath), newMedia);
      console.log('Voice note uploaded with document ID:', docRef.id);

      // Reset state after successful upload
      setAudioUrl(null);
      setCaption('');
      audioBlobRef.current = null;
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error uploading voice note:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="voiceNote-modal-overlay">
      <div className="voiceNote-modal-content">
        <button className="voiceNote-modal-close" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>

        {!audioUrl && !isRecording && (
          <button className="voiceNote-record-btn" onClick={handleVoiceNote}>
            <span className="material-icons">mic</span>
          </button>
        )}

        {isRecording && (
          <div>
            <button className="voiceNote-stop-btn" onClick={stopRecording}>
              <span className="blinking-mic material-icons">mic</span>
            </button>
          </div>
        )}

        {audioUrl && (
          <div className="voiceNote-actions">
            <button onClick={handlePlayBack} className="voiceNote-play-btn">
              <span className="material-icons">play_arrow</span>
            </button>
            <button onClick={handleRedo} className="voiceNote-redo-btn">
              <span className="material-icons">refresh</span>
            </button>
          </div>
        )}

        {audioUrl && (
          <div className="voiceNote-caption">
            <textarea
              placeholder="Add a caption..."
              value={caption}
              onChange={handleCaptionChange}
              className="voiceNote-caption-input"
            />
          </div>
        )}

        {audioUrl && (
          <button
            className="voiceNote-submit-btn"
            onClick={handleSubmit}
          >
            {loading ? 'Uploading...' : 'Submit'}
          </button>
        )}
      </div>
    </div>
  );
}

export default VoiceNoteModal;
