// src/EventCode.js
import React, { useState } from 'react';
import './EventCode.css';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase-config';
// import SignUpForm from './SignUpForm';

function EventCode({ onSubmit }) {
  const [eventCode, setEventCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (eventCode.trim()) {
      const eventDocRef = doc(firestore, 'events', eventCode);
      const eventDoc = await getDoc(eventDocRef);

      if (eventDoc.exists()) {
        onSubmit(eventCode);
      } else {
        setErrorMessage('Invalid event code. Please try again.');
      }
    }
  };

  return (
    <section id="features-entercode">
    <div className="enter-event-code">
      <h2>Enter Event Code</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={eventCode}
          onChange={(e) => {
            setEventCode(e.target.value);
            setErrorMessage('');
          }}
          placeholder="Enter your event code"
        />
        <button className="event-code-submit-button" type="submit">
          <span className="material-icons">send</span>
        </button>      
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
    </section>
  );
}

export default EventCode;
